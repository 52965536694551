<template>
  <div class="text-sm leading-xs text-grey-dark">
    {{ t('forms.captcha.google.intro') }}
    <a
      class="underline text-primary-base"
      href="https://policies.google.com/privacy"
      target="_blank"
      >{{ t('forms.captcha.google.privacy') }}</a
    >
    &
    <a
      class="underline text-primary-base"
      href="https://policies.google.com/terms"
      target="_blank"
    >
      {{ t('forms.captcha.google.terms') }}
    </a>
    <span v-if="$siteIdent === SiteIdent.miniluNl">
      {{ t('forms.captcha.google.introSuffix') }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { SiteIdent } from '~/utils/types';
const { t } = useTrans();
</script>
