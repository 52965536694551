import { load as recaptcha } from 'recaptcha-v3';

export default function useRecaptcha() {
  const usesCaptcha = !!useRuntimeConfig().public.RECAPTCHA_SITE_KEY;
  const captcha = ref<Awaited<ReturnType<typeof recaptcha>>>(null);
  if (import.meta.client) {
    onBeforeMount(async () => {
      if (usesCaptcha) {
        captcha.value = await recaptcha(
          useRuntimeConfig().public.RECAPTCHA_SITE_KEY,
          {
            autoHideBadge: true,
          },
        );
      }
    });
  }
  return { captcha };
}
